import supportsTime from 'time-input-polyfill/supportsTime';
import TimePolyfill from 'time-input-polyfill';

if (!supportsTime) {
    // Converting to an array for IE support
    var $$inputs = [].slice.call(document.querySelectorAll('input[type="time"]'));
    $$inputs.forEach(function ($input) {
        new TimePolyfill($input);
    })
}
